@font-face {
  font-family: Px-Grotesk-Pan;
  src: url("Px-Grotesk-Pan-Thin-Trial.84d1f4b4.woff") format("woff"), url("Px-Grotesk-Pan-Thin-Trial.70694763.woff2") format("woff2"), url("Px-Grotesk-Pan-Thin-Trial.442a8229.otf") format("otf");
  font-weight: 300;
}

@font-face {
  font-family: Px-Grotesk-Pan;
  src: url("Px-Grotesk-Pan-Light-Trial.4e5e8aa5.woff") format("woff"), url("Px-Grotesk-Pan-Light-Trial.eddbee0b.woff2") format("woff2"), url("Px-Grotesk-Pan-Light-Trial.189be04f.otf") format("otf");
  font-weight: 400;
}

@font-face {
  font-family: Px-Grotesk-Pan;
  src: url("Px-Grotesk-Pan-Regular-Trial.25175f58.woff") format("woff"), url("Px-Grotesk-Pan-Regular-Trial.e21fe9e0.woff2") format("woff2"), url("Px-Grotesk-Pan-Regular-Trial.cca83947.otf") format("otf");
  font-weight: 500;
}

@font-face {
  font-family: Px-Grotesk-Pan;
  src: url("Px-Grotesk-Pan-Bold-Trial.c4bba980.woff") format("woff"), url("Px-Grotesk-Pan-Bold-Trial.17aadb3c.woff2") format("woff2"), url("Px-Grotesk-Pan-Bold-Trial.a3e1c9f0.otf") format("otf");
  font-weight: 700;
}

@font-face {
  font-family: Px-Grotesk-Pan;
  src: url("Px-Grotesk-Pan-Black-Trial.665cac7d.woff") format("woff"), url("Px-Grotesk-Pan-Black-Trial.47cdc6f1.woff2") format("woff2"), url("Px-Grotesk-Pan-Black-Trial.41fb6e42.otf") format("otf");
  font-weight: 900;
}

/*# sourceMappingURL=index.f5276b1b.css.map */
