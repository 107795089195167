@font-face {
  font-family: Px-Grotesk-Pan;
  src: url(./Px-Grotesk-Pan-Thin-Trial.woff) format("woff"),
    url(./Px-Grotesk-Pan-Thin-Trial.woff2) format("woff2"),
    url(./Px-Grotesk-Pan-Thin-Trial.otf) format("otf");
  font-weight: 300;
}

@font-face {
  font-family: Px-Grotesk-Pan;
  src: url(./Px-Grotesk-Pan-Light-Trial.woff) format("woff"),
    url(./Px-Grotesk-Pan-Light-Trial.woff2) format("woff2"),
    url(./Px-Grotesk-Pan-Light-Trial.otf) format("otf");
  font-weight: 400;
}

@font-face {
  font-family: Px-Grotesk-Pan;
  src: url(./Px-Grotesk-Pan-Regular-Trial.woff) format("woff"),
    url(./Px-Grotesk-Pan-Regular-Trial.woff2) format("woff2"),
    url(./Px-Grotesk-Pan-Regular-Trial.otf) format("otf");
  font-weight: 500;
}

@font-face {
  font-family: Px-Grotesk-Pan;
  src: url(./Px-Grotesk-Pan-Bold-Trial.woff) format("woff"),
    url(./Px-Grotesk-Pan-Bold-Trial.woff2) format("woff2"),
    url(./Px-Grotesk-Pan-Bold-Trial.otf) format("otf");
  font-weight: 700;
}

@font-face {
  font-family: Px-Grotesk-Pan;
  src: url(./Px-Grotesk-Pan-Black-Trial.woff) format("woff"),
    url(./Px-Grotesk-Pan-Black-Trial.woff2) format("woff2"),
    url(./Px-Grotesk-Pan-Black-Trial.otf) format("otf");
  font-weight: 900;
}